<template>
  <el-dialog v-model="dialogVisible" :title="dialogVisibletitle" :close-on-click-modal="false" width="560px">
    <div class="dp-f">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="120px"
        class="demo-ruleForm w100" label-position="left">
        <el-form-item label="收件人姓名：" prop='recipientName' class="is-required">
          <el-input v-model="ruleForm.recipientName" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入收件人姓名" />
        </el-form-item>
        <el-form-item label="电话号码：" prop='phone' class="is-required">
          <el-input v-model="ruleForm.phone" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入电话号码" />
        </el-form-item>
        <el-form-item label="寄送地址：" prop='address' class="is-required">
          <el-input v-model="ruleForm.address" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入寄送地址" />
        </el-form-item>
        <el-form-item label="邮编：" prop='postcodes' class="is-required">
          <el-input v-model="ruleForm.postcodes" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入邮编" />
        </el-form-item>
        
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='40' title="确定" CSStype=2 @buttonclick="submitForm()"
          v-model:loading="dialogloading"></oabutton>
        <oabutton class="searcML" width='120' height='40' title="取消" CSStype=3 @buttonclick="dialogVisible = false">
        </oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { objdata_ret, objdata_set } from "@/utils/server/format";
import { handleMessage } from "@/utils/server/confirm";
import { validPhone } from "@/utils/server/validate.js";
import { httpToken } from "@/utils/request";
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const dialogVisibletitle = ref(false)//弹框标题
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  recipientName: '',//收件人姓名
  phone: '',//电话号码
  address: '',//寄送地址
  postcodes: '',//邮编
})
// 校验手机号格式
const validPhoneData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入电话号码'))
  } else {
    if (validPhone(value)) {
      callback()
    } else
      callback(new Error('电话号码格式错误'))
  }
}
const rules = reactive({//from 表单校验
  recipientName: [
    { required: true, message: '请输入收件人姓名', trigger: 'blur' },
  ],
  phone: [
    { validator: validPhoneData, trigger: 'blur' }
  ],
  address: [
    { required: true, message: '请输入寄送地址', trigger: 'blur' },
  ],
  postcodes: [
    { required: true, message: '请输入邮编', trigger: 'blur' },
  ],
})
// 表单重置
const fromDataReset = (() => {
  objdata_ret(ruleForm)
})
const addclick = () => {
  fromDataReset()
  dialogloading.value = false
  dialogVisibletitle.value = '新增寄送地址'
  dialogVisible.value = true
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
}
const editclick = ((row) => {
  fromDataReset()
  objdata_set(ruleForm, row)
  dialogloading.value = false
  dialogVisibletitle.value = '修改寄送地址'
  dialogVisible.value = true
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
})
// 表单提交
const submitForm = async () => {
  dialogloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      emit('Emit', ruleForm)
      dialogloading.value = false
      dialogVisible.value = false
    } else {
      dialogloading.value = false
    }
  })
}
defineExpose({
  addclick,
  editclick,
});
</script>